import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Icon, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosArrowDropdown } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				CORESTRENGTH HUB
			</title>
			<meta name={"description"} content={"Ми більше, ніж тренажерний зал – ми спільнота, яка зосереджена на тому, щоб допомогти вам розкрити свій потенціал і досягти здоров’я на всіх рівнях."} />
			<meta property={"og:title"} content={"CORESTRENGTH HUB"} />
			<meta property={"og:description"} content={"Ми більше, ніж тренажерний зал – ми спільнота, яка зосереджена на тому, щоб допомогти вам розкрити свій потенціал і досягти здоров’я на всіх рівнях."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text1">
				CoreStrength Hub
			</Override>
			<Override slot="link3" />
			<Override slot="quarklycommunityKitMenuOverride6" />
		</Components.Header>
		<Section overflow-x="hidden" overflow-y="hidden" quarkly-title="Hero-6" background="rgba(0, 0, 0, 0) linear-gradient(0deg,rgba(0,0,0,0) 0%,--color-secondary 100%) 0% 0% /auto repeat scroll padding-box">
			<Override slot="SectionContent" width="100%" flex="1 1 0%" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				position="static"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="140px 80px 140px 80px"
					position="static"
					lg-width="100%"
					lg-padding="80px 30px 80px 30px"
					sm-padding="80px 30px 40px 30px"
				>
					<Text margin="0px 0px 20px 0px" font="--headline2" sm-font="normal 700 42px/1.2 --fontFamily-sans" text-transform="uppercase">
						Ласкаво просимо до CoreStrength Hub, де починається ваша фітнес-еволюція.
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 300 21px/1.5 --fontFamily-googlePrompt">
						Ми більше, ніж тренажерний зал – ми спільнота, яка зосереджена на тому, щоб допомогти вам розкрити свій потенціал і досягти здоров’я на всіх рівнях. Ідеально підходить як для початківців, так і для досвідчених спортсменів, наш заклад обладнаний для підтримки кожного кроку вашої фітнес-мандрівки.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="#3f24d8"
							border-radius="8px"
							font="--lead"
							sm-margin="0px 0 15px 0px"
							border-width="2px"
							border-style="solid"
							border-color="#3f24d8"
							hover-color="--darkL1"
							hover-background="rgba(63, 36, 216, 0)"
							transition="background-color 0.2s ease 0s"
							type="link"
							text-decoration-line="initial"
							href="/services"
						>
							Наші послуги
						</Button>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="758px"
					display="flex"
					margin="0px -50px 0px 0px"
					position="static"
					bottom="-298px"
					height="716px"
					left="614px"
					right="-52px"
					top="-28px"
					flex="1 1 0%"
					lg-margin="0px 0 0px 0px"
					lg-align-self="center"
					lg-padding="0px 0px 80px 0px"
					sm-flex="1 1 0%"
					sm-align-self="auto"
					sm-width="100%"
					sm-padding="0px 0px 40px 0px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						margin="80px 0 0 25px"
						width="33%"
						lg-margin="80px 0 0 0"
						sm-margin="50px 0 0 0"
					>
						<Image
							src="https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-5%20%281%29.jpg?v=2024-05-31T12:21:21.982Z"
							display="block"
							height="300px"
							border-radius="25px"
							object-fit="cover"
							width="100%"
							sm-height="200px"
							sm-width="200px"
							srcSet="https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-5%20%281%29.jpg?v=2024-05-31T12%3A21%3A21.982Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-5%20%281%29.jpg?v=2024-05-31T12%3A21%3A21.982Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-5%20%281%29.jpg?v=2024-05-31T12%3A21%3A21.982Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-5%20%281%29.jpg?v=2024-05-31T12%3A21%3A21.982Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-5%20%281%29.jpg?v=2024-05-31T12%3A21%3A21.982Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-5%20%281%29.jpg?v=2024-05-31T12%3A21%3A21.982Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-5%20%281%29.jpg?v=2024-05-31T12%3A21%3A21.982Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						flex-direction="column"
						justify-content="center"
						margin="80px 0 0 25px"
						width="33%"
						sm-align-self="center"
						sm-margin="50px 0 0 15px"
					>
						<Image
							src="https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-2.jpg?v=2024-05-31T12:21:21.995Z"
							display="block"
							max-width="100%"
							height="300px"
							object-fit="cover"
							border-radius="15px"
							margin="0px 0px 15px 0px"
							sm-height="200px"
							sm-width="200px"
							srcSet="https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-2.jpg?v=2024-05-31T12%3A21%3A21.995Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-2.jpg?v=2024-05-31T12%3A21%3A21.995Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-2.jpg?v=2024-05-31T12%3A21%3A21.995Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-2.jpg?v=2024-05-31T12%3A21%3A21.995Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-2.jpg?v=2024-05-31T12%3A21%3A21.995Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-2.jpg?v=2024-05-31T12%3A21%3A21.995Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-2.jpg?v=2024-05-31T12%3A21%3A21.995Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Image
							src="https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-3.jpg?v=2024-05-31T12:21:21.980Z"
							display="block"
							max-width="100%"
							height="300px"
							object-fit="cover"
							border-radius="15px"
							margin="0px 0px 15px 0px"
							sm-height="200px"
							sm-width="200px"
							srcSet="https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-3.jpg?v=2024-05-31T12%3A21%3A21.980Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-3.jpg?v=2024-05-31T12%3A21%3A21.980Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-3.jpg?v=2024-05-31T12%3A21%3A21.980Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-3.jpg?v=2024-05-31T12%3A21%3A21.980Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-3.jpg?v=2024-05-31T12%3A21%3A21.980Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-3.jpg?v=2024-05-31T12%3A21%3A21.980Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-3.jpg?v=2024-05-31T12%3A21%3A21.980Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						flex-direction="column"
						margin="0 0 0 25px"
						width="33%"
						justify-content="flex-start"
						sm-margin="0 0 0 15px"
					>
						<Image
							src="https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-1.jpg?v=2024-05-31T12:21:21.976Z"
							display="block"
							max-width="100%"
							height="300px"
							object-fit="cover"
							border-radius="15px"
							margin="0px 0px 15px 0px"
							sm-height="200px"
							sm-width="200px"
							srcSet="https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-1.jpg?v=2024-05-31T12%3A21%3A21.976Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-1.jpg?v=2024-05-31T12%3A21%3A21.976Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-1.jpg?v=2024-05-31T12%3A21%3A21.976Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-1.jpg?v=2024-05-31T12%3A21%3A21.976Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-1.jpg?v=2024-05-31T12%3A21%3A21.976Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-1.jpg?v=2024-05-31T12%3A21%3A21.976Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-1.jpg?v=2024-05-31T12%3A21%3A21.976Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Image
							src="https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-1.jpg?v=2024-05-31T12:21:21.965Z"
							display="block"
							max-width="100%"
							height="300px"
							object-fit="cover"
							border-radius="15px"
							margin="0px 0px 15px 0px"
							sm-height="200px"
							sm-width="200px"
							object-position="left"
							srcSet="https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-1.jpg?v=2024-05-31T12%3A21%3A21.965Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-1.jpg?v=2024-05-31T12%3A21%3A21.965Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-1.jpg?v=2024-05-31T12%3A21%3A21.965Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-1.jpg?v=2024-05-31T12%3A21%3A21.965Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-1.jpg?v=2024-05-31T12%3A21%3A21.965Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-1.jpg?v=2024-05-31T12%3A21%3A21.965Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-1.jpg?v=2024-05-31T12%3A21%3A21.965Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 80px 0" sm-padding="60px 0 60px 0">
			<Box display="grid" grid-template-columns="repeat(2, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="io"
						icon={IoIosArrowDropdown}
						margin="0px 0px 22px 0px"
						color="--primary"
						size="36px"
					/>
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Найсучасніше обладнання
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						Відчуйте найкраще в сучасних фітнес-технологіях, створених для досягнення максимальних результатів.
					</Text>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="io"
						icon={IoIosArrowDropdown}
						margin="0px 0px 22px 0px"
						color="--primary"
						size="36px"
					/>
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Експертний персонал
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						Наші професійні тренери надають індивідуальні рекомендації, які забезпечують безпечні та ефективні тренування.
					</Text>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="io"
						icon={IoIosArrowDropdown}
						margin="0px 0px 22px 0px"
						color="--primary"
						size="36px"
					/>
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Спільнота, що надихає
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						Станьте частиною спільноти, яка мотивує та підтримує один одного в досягненні особистих рекордів.
					</Text>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="io"
						icon={IoIosArrowDropdown}
						margin="0px 0px 22px 0px"
						color="--primary"
						size="36px"
					/>
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Просте членство
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						Вибирайте з наших гнучких варіантів членства, адаптованих відповідно до ваших потреб, без неочікуваних комісій.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/unique-handdrawn-floral-modern-shapes.png?v=2023-04-27T21:48:23.623Z) 0 0/contain no-repeat scroll padding-box"
					padding="100px 50px 100px 50px"
					md-margin="0px 0px 50px 0px"
					md-padding="50px 50px 50px 50px"
				>
					<Image
						src="https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-1.jpg?v=2024-05-31T12:21:21.976Z"
						display="block"
						max-width="100%"
						width="350px"
						srcSet="https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-1.jpg?v=2024-05-31T12%3A21%3A21.976Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-1.jpg?v=2024-05-31T12%3A21%3A21.976Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-1.jpg?v=2024-05-31T12%3A21%3A21.976Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-1.jpg?v=2024-05-31T12%3A21%3A21.976Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-1.jpg?v=2024-05-31T12%3A21%3A21.976Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-1.jpg?v=2024-05-31T12%3A21%3A21.976Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/2-1.jpg?v=2024-05-31T12%3A21%3A21.976Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="normal 500 42px/1.2 --fontFamily-serifGaramond" sm-margin="0px 0px 30px 0px">
						Про CoreStrength Hub
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="--darkL1">
						CoreStrength Hub пропонує динамічний підхід до фітнесу з рядом опцій, призначених для зміцнення вашого здоров’я. Наш тренажерний зал може похвалитися найновішим обладнанням, різноманітними пропозиціями для занять і персоналізованими послугами тренувань, і все це в надихаючому та сприятливому середовищі. Незалежно від того, піднімаєте ви тяжкості, займаєтеся кардіотренуваннями чи берете участь у заняттях, кожен візит до CoreStrength Hub наближає вас до ваших цілей.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" margin="0px 0px 0px 0px" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="50%"
				padding="0px 40px 0px 15px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 60px 0px"
			>
				<Text margin="0px 0px 24px 0px" color="#ffffff" font="--headline1" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					Огляд послуг
				</Text>
				<Text margin="0px 0px 0px 0px" color="--lightD2" font="--lead">
					У CoreStrength Hub ми прагнемо надавати повний набір фітнес-послуг, розроблених для всіх рівнів досвіду та фітнес-цілей. Наш заклад — це не просто тренажерний зал, це комплексне фітнес-середовище, де кожен інструмент і послуга створені, щоб допомогти вам повністю розкрити свій потенціал.
				</Text>
				<Button
					margin="1rem 15px 0px 0px"
					padding="12px 28px 12px 28px"
					background="#3f24d8"
					border-radius="8px"
					font="--lead"
					sm-margin="0px 0 15px 0px"
					border-width="2px"
					border-style="solid"
					border-color="#3f24d8"
					hover-color="--darkL1"
					hover-background="rgba(63, 36, 216, 0)"
					transition="background-color 0.2s ease 0s"
					type="link"
					text-decoration-line="initial"
					href="/services"
					align-self="flex-start"
				>
					Всі послуги
				</Button>
			</Box>
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="50%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
			>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline3" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Персональні тренування
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline3" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Групові фітнес-заняття
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px" />
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline3" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Йога та пілатес
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px" />
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline3" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Інтервальне тренування високої інтенсивності (HIIT)
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px" />
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline3" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Класи силових тренувань
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px" />
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 0px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline3" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Консультації з питань харчування
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px" />
				</Box>
			</Box>
		</Section>
		<Components.Contacts>
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text4" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text" />
		</Components.Contacts>
		<Components.Footer>
			<Override slot="link3" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6620d5f56bbd20002369dde3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});